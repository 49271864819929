
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import clubsStub from "./clubs.stub"

export default Vue.extend({
  name: "ClubsForm",

  props: {
    value: { type: Object, default: () => clubsStub }
  },

  computed: {
    ...mapState("clubs", {
      clubsStateCountries: "countries"
    }),
    ...mapState("ranges", {
      rangesStateListIsLoading: "listIsLoading",
      rangesStateList: "list"
    })
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(data): void {
        this.$emit("input", data)
      }
    }
  },

  created() {
    this.rangesActionsList()
  },

  methods: {
    ...mapActions("ranges", {
      rangesActionsList: "list"
    }),

    submit(cb): void {
      this.$refs.localForm.validate()
        ? cb()
        : this.$error(this.$t("validationError"))
    },

    resetFields(): void {
      this.$refs.localForm.reset()
    }
  }
})
