
import Vue from "vue"
import { mapActions, mapState, mapGetters } from "vuex"
import DataGrid from "@/components/DataGrid.vue"
import ClubsEditDialog from "./ClubsEditDialog.vue"
import MembersListTable from "./members/ClubsMembersListTable.vue"
import MembersCreateDialog from "./members/ClubsMembersCreateDialog.vue"
import MembersEditDialog from "./members/ClubsMembersEditDialog.vue"
import Confirm from "@/components/Confirm.vue"

export default Vue.extend({
  name: "ClubsViewScreen",

  components: {
    DataGrid,
    ClubsEditDialog,
    MembersListTable,
    MembersCreateDialog,
    MembersEditDialog,
    Confirm
  },

  data: () => ({
    clubsEditDialogShown: false,
    membersCreateDialogShown: false,
    membersEditDialogShown: false,
    membersEditItem: {}
  }),

  computed: {
    ...mapState("clubs", {
      clubsStateSelectedIsLoading: "selectedIsLoading",
      clubsStateRemoveOneIsLoading: "removeOneIsLoading",
      clubsStateSelected: "selected",
    }),
    ...mapState("clubs/members", {
      membersStateListIsLoading: "listIsLoading",
      membersStateList: "list",
      membersStateRemoveOneIsLoading: "removeOneIsLoading",
      membersStateRemoveManyIsLoading: "removeManyIsLoading"
    }),
    ...mapGetters("clubs/members", {
      getListByClubId: "listByClubId"
    }),
    members() {
      const clubId = this.$route.params.clubId
      return clubId
        ? this.getListByClubId(clubId)
        // ? this.membersStateList.filter((c) => c.clubId === clubId)
        : []
    },
    clubsIsLoading(): boolean {
      return (
        this.clubsStateSelectedIsLoading ||
        this.clubsStateRemoveOneIsLoading
      )
    },
    membersIsLoading(): boolean {
      return (
        this.membersStateListIsLoading ||
        this.membersStateRemoveOneIsLoading ||
        this.membersStateRemoveManyIsLoading
      )
    }
  },

  watch: {
    "$route.params.clubId": {
      immediate: true,
      handler(id): Promise<void> {
        this.clubsActionsSelect({ id })
        if(this.membersStateList.length) return
        this.membersActionsList()
      }
    }
  },

  methods: {
    ...mapActions("clubs", {
      clubsActionsSelect: "select",
      clubsActionsRemoveOne: "removeOne"
    }),

    ...mapActions("clubs/members", {
      membersActionsList: "list",
      membersActionsRemoveOne: "removeOne",
      membersActionsRemoveMany: "removeMany"
    }),

    clubsEditDialogOpen(): void {
      this.clubsEditDialogShown = true
    },

    membersCreateDialogOpen(): void {
      this.membersCreateDialogShown = true
    },

    membersEditDialogOpen(member): void {
      this.membersEditDialogShown = true
      this.membersEditItem = member
    },

    async clubsRemoveOne(club): Promise<void> {
      if(!await this.$refs.confirm.dangerous(
        this.$t("clubsRemoveOneConfirmation", {
          clubName: club.name,
          memberCount: club.membersCount
        })
      )) return

      try {
        await this.clubsActionsRemoveOne(club)
        this.$success(this.$t("clubsActionsRemoveOneSuccess", {
          clubName: club.name,
          memberCount: club.membersCount
        }))
        this.$router.push("/clubs")
      } catch(e) {
        this.$error(e.message)
      }
    },

    async membersRemoveOne(clubMember): Promise<void> {
      const fullName = `${clubMember.firstName} ${clubMember.lastName}`

      if(!await this.$refs.confirm.dangerous(
        this.$t("membersRemoveOneConfirmation", {
          fullName
        })
      )) return

      try {
        await this.membersActionsRemoveOne(clubMember)
        this.$success(this.$t("membersActionsRemoveOne", {
          fullName
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async membersRemoveMany(clubMembers): Promise<void> {
      const clubMembersCount = clubMembers.length

      if(!await this.$refs.confirm.dangerous(
        this.$t("membersRemoveManyConfirmation", {
          clubMembersCount
        })
      )) return

      try {
        await this.membersActionsRemoveMany(clubMembers)
        this.$success(this.$t("membersActionsRemoveManySuccess", {
          clubMembersCount
        }))
      } catch(e) {
        this.$error(e.message)
      }
    }
  }
})
