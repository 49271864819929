
import Vue from "vue"
import TableFilterSearch
  from "@/components/TableFilterSearch.vue"

export default Vue.extend({
  name: "ClubsMembersListTable",

  components: {
    TableFilterSearch
  },

  props: {
    members: { type: Array, default: (): [] => [] },
    loading: { type: Boolean, default: true }
  },

  data() {
    return {
      selection: [],
      searchFilter: "",
      headers: [{
        value: "firstName",
        text: this.$t("columnFirstNameLabel")
      }, {
        value: "lastName",
        text: this.$t("columnLastNameLabel")
      }, {
        value: "emailAddress",
        text: this.$t("columnEmailAddressLabel")
      }, {
        value: "phoneNumber",
        text: this.$t("columnPhoneNumberLabel")
      }, {
        value: "streetAddress",
        text: this.$t("columnStreetAddressLabel")
      }, {
        value: "country",
        text: this.$t("columnCountryLabel")
      }, {
        value: "actions",
        sortable: false,
        align: "right"
      }]
    }
  },

  computed: {
    clubsMembersHasSelection(): boolean {
      return this.selection.length > 0
    }
  },

  methods: {
    createDialogOpen(): void {
      this.$emit("createDialogOpen")
    },

    editDialogOpen(member): void {
      this.$emit("editDialogOpen", member)
    },

    removeOne(member): void {
      this.$emit("removeOne", member)
    },

    removeMany(members): void {
      this.$emit("removeMany", members)
    }
  }
})
