
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import ActionDialog from "@/components/ActionDialog.vue"
import ClubsForm from "./ClubsForm.vue"
import clubsStub from "./clubs.stub"

export default Vue.extend({
  name: "ClubsCreateDialog",

  components: {
    ActionDialog,
    ClubsForm
  },

  props: {
    shown: { type: Boolean, default: false }
  },

  data: function() {
    return {
      visible: this.shown,
      form: { ...clubsStub }
    }
  },

  computed: mapState("clubs", {
    clubsStateCreateIsLoading: "createIsLoading"
  }),

  watch: {
    visible(visible): void {
      this.$emit("update:shown", visible)
    },
    shown(visible): void {
      this.visible = visible
    }
  },

  methods: {
    ...mapActions("clubs", {
      clubsActionsCreate: "create"
    }),

    submit(): void {
      this.$refs.clubsForm.submit(async () => {
        try {
          await this.clubsActionsCreate(this.form)
          this.$success(this.$t("clubsActionsCreateSuccess", {
            clubsName: this.form.name
          }))
          this.clear()
          this.close()
        } catch(e) {
          this.$error(e.message)
        }
      })
    },

    clear(): void {
      this.$refs.clubsForm.resetFields()
      this.form = { ...clubsStub }
    },

    close(): void {
      this.visible = false
    }
  }
})
