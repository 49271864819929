
import Vue from "vue"
import { mapActions, mapState, mapGetters } from "vuex"
import ClubsListTable from "./ClubsListTable.vue"
import ClubsCreateDialog from "./ClubsCreateDialog.vue"
import ClubsEditDialog from "./ClubsEditDialog.vue"
import MembersListTable
  from "./members/ClubsMembersListTable.vue"
import MembersCreateDialog
  from "./members/ClubsMembersCreateDialog.vue"
import MembersEditDialog
  from "./members/ClubsMembersEditDialog.vue"
import Confirm from "@/components/Confirm.vue"

export default Vue.extend({
  name: "ClubsListScreen",

  components: {
    ClubsListTable,
    ClubsCreateDialog,
    ClubsEditDialog,
    MembersListTable,
    MembersCreateDialog,
    MembersEditDialog,
    Confirm
  },

  data: () => ({
    activeTab: 0,
    clubsCreateDialogShow: false,
    clubsEditDialogShow: false,
    clubsEditDialogClub: {},
    membersCreateDialogShown: false,
    membersEditDialogShown: false,
    membersEditItem: {}
  }),

  computed: {
    ...mapState("clubs", {
      clubsStateListIsLoading: "listIsLoading",
      clubsStateList: "list",
      clubsStateRemoveOneIsLoading: "removeOneIsLoading",
      clubsStateRemoveManyIsLoading: "removeManyIsLoading",
    }),

    ...mapState("clubs/members", {
      membersStateListIsLoading: "listIsLoading",
      membersStateRemoveOneIsLoading: "removeOneIsLoading",
      membersStateRemoveManyIsLoading: "removeManyIsLoading"
    }),

    ...mapGetters("clubs/members", {
      membersGetListNoClub: "listNoClub"
    }),

    clubsIsLoading(): boolean {
      return (
        this.clubsStateListIsLoading ||
        this.clubsStateRemoveOneIsLoading ||
        this.clubsStateRemoveManyIsLoading
      )
    },

    membersIsLoading(): boolean {
      return (
        this.membersStateListIsLoading ||
        this.membersStateRemoveOneIsLoading ||
        this.membersStateRemoveManyIsLoading
      )
    }
  },

  created() {
    this.clubsActionsList()
    this.membersActionsList()
  },

  methods: {
    ...mapActions("clubs", {
      clubsActionsList: "list",
      clubsActionsRemoveOne: "removeOne",
      clubsActionsRemoveMany: "removeMany"
    }),

    ...mapActions("clubs/members", {
      membersActionsList: "list",
      membersActionsRemoveOne: "removeOne",
      membersActionsRemoveMany: "removeMany"
    }),

    clubsCreateDialogOpen(): void {
      this.clubsCreateDialogShow = true
    },

    clubsEditDialogOpen(club): void {
      this.clubsEditDialogShow = true
      this.clubsEditDialogClub = club
    },

    membersCreateDialogOpen(): void {
      this.membersCreateDialogShown = true
    },

    membersEditDialogOpen(member): void {
      this.membersEditItem = member
      this.membersEditDialogShown = true
    },

    async clubsRemoveOne(club): Promise<void> {
      if(!await this.$refs.confirm.dangerous(
        this.$t("clubsRemoveOneConfirmation", {
          club: club.name,
          clubMemberCount: club.membersCount
        })
      )) return

      try {
        await this.clubsActionsRemoveOne(club)
        this.$success(this.$t("clubsRemoveOneSuccess", {
          clubName: club.name,
          clubMemberCount: club.membersCount
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async clubsRemoveMany(clubs): Promise<void> {
      const clubsCount = clubs.length
      const clubMemberCount = clubs.reduce(
        (total, { membersCount }) => total + membersCount, 0
      )

      if(!await this.$refs.confirm.dangerous(
        this.$t("clubsRemoveManyConfirmation", {
          clubsCount,
          clubMemberCount
        })
      )) return

      try {
        await this.clubsActionsRemoveMany(clubs)
        this.$success(this.$t("clubsActionsRemoveManySuccess", {
          clubsCount,
          clubMemberCount
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async membersRemoveOne(clubMember): Promise<void> {
      const fullName = `${clubMember.firstName} ${clubMember.lastName}`

      if(!await this.$refs.confirm.dangerous(
        this.$t("membersRemoveOneConfirmation", {
          fullName
        })
      )) return

      try {
        await this.membersActionsRemoveOne(clubMember)
        this.$success(this.$t("membersActionsRemoveOne", {
          fullName
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async membersRemoveMany(clubMembers): Promise<void> {
      const clubMembersCount = clubMembers.length

      if(!await this.$refs.confirm.dangerous(
        this.$t("membersRemoveManyConfirmation", {
          clubMembersCount
        })
      )) return

      try {
        await this.membersActionsRemoveMany(clubMembers)
        this.$success(this.$t("membersActionsRemoveManySuccess", {
          clubMembersCount
        }))
      } catch(e) {
        this.$error(e.message)
      }
    }
  }
})
