
import Vue from "vue"
import TableFilterSearch from "@/components/TableFilterSearch.vue"

export default Vue.extend({
  name: "ClubsListTable",

  components: {
    TableFilterSearch
  },

  props: {
    clubs: { type: Array, default: (): [] => [] },
    loading: { type: Boolean, default: true }
  },

  data() {
    return {
      selection: [],
      searchFilter: "",
      headers: [{
        value: "shortName",
        text: this.$t("columnShortNameLabel")
      }, {
        value: "name",
        text: this.$t("columnNameLabel")
      }, {
        value: "zipCode",
        text: this.$t("columnAreaLabel")
      }, {
        value: "leaderFullName",
        text: this.$t("columnLeaderFullNameLabel"),
        sortable: false
      }, {
        value: "membersCount",
        text: this.$t("columnMembersCountLabel"),
        sortable: false
      }, {
        value: "actions",
        sortable: false,
        align: "right"
      }]
    }
  },

  computed: {
    hasSelection(): boolean {
      return this.selection.length > 0
    }
  },

  methods: {
    createDialogOpen(): void {
      this.$emit("createDialogOpen")
    },

    editDialogOpen(club): void {
      this.$emit("editDialogOpen", club)
    },

    removeOne(club): void {
      this.$emit("removeOne", club)
    },

    removeMany(clubs): void {
      this.$emit("removeMany", clubs)
    },

    tableRowClick(club): void {
      this.$router.push(`/clubs/${club.id}`)
    }
  }
})
